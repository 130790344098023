<template>
  <div class="hold-transition skin-purple sidebar-mini">
    <div class="wrapper">
      <!-- 页面头部 -->
      <header class="main-header">
        <!-- Logo -->
        <router-link to="/datagrail" class="logo">
          <!-- mini logo for sidebar mini 50x50 pixels -->
          <span class="logo-mini"><b>数据</b></span>
          <!-- logo for regular state and mobile devices -->
          <span class="logo-lg"><b>推广中心</b>后台管理</span>
        </router-link>

        <!-- Header Navbar: style can be found in header.less -->
        <nav class="navbar navbar-static-top">
          <!-- Sidebar toggle button-->
          <a
            href="#"
            class="sidebar-toggle"
            data-toggle="offcanvas"
            role="button"
          >
            <span class="sr-only">Toggle navigation</span>
          </a>

          <div class="navbar-custom-menu">
            <ul class="nav navbar-nav">
              <!-- User Account: style can be found in dropdown.less -->
              <li class="dropdown user user-menu">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                  <img
                    v-if="userInfo.Avatar || imageUrl"
                    :src="userInfo.Avatar"
                    class="user-image"
                    alt="User Image"
                  />
                  <img
                    v-else
                    src="../assets/img/userimg.png"
                    class="user-image"
                    alt="User Image"
                  />
                  <span class="hidden-xs">{{ userInfo.InviteUserName }}</span>
                </a>
                <ul class="dropdown-menu" @click="UserView()">
                  <!-- User image -->
                  <li class="user-header">
                    <img
                      v-if="userInfo.Avatar || imageUrl"
                      :src="userInfo.Avatar || imageUrl"
                      class="img-circle"
                      alt="User Image"
                    />
                    <img
                      v-else
                      src="../assets/img/userimg.png"
                      class="img-circle"
                      alt="User Image"
                    />
                    <!-- <img
                      :src="
                        'http://4px.eztao.net/api/Auth/InviteQrCode/?id=' +
                        userInfo.InviteUserId
                      "
                      width="120"
                      height="120"
                      id="img-code"
                      class="img-circle"
                      alt="User Image"
                      style="display:none"
                    /> -->
                    <p>
                      {{ userInfo.InviteUserName }}
                      <small>最后登录 11:20AM</small>
                    </p>
                  </li>
                  <!-- Menu Body 
                        <li class="user-body">
                            <div class="row">
                                <div class="col-xs-4 text-center">
                                    <a href="#">Followers</a>
                                </div>
                                <div class="col-xs-4 text-center">
                                    <a href="#">Sales</a>
                                </div>
                                <div class="col-xs-4 text-center">
                                    <a href="#">Friends</a>
                                </div>
                            </div>
                        </li>-->
                  <!-- Menu Footer-->
                  <li class="user-footer">
                    <div class="pull-left">
                      <a href="#" class="btn btn-default btn-flat">修改密码</a>
                    </div>
                    <div class="pull-right">
                      <a
                        href="#"
                        @click="RemoveUser()"
                        class="btn btn-default btn-flat"
                        >注销</a
                      >
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <!-- 页面头部 /-->

      <!-- 导航侧栏 -->
      <aside class="main-sidebar">
        <!-- sidebar: style can be found in sidebar.less -->
        <section class="sidebar">
          <!-- Sidebar user panel -->
          <!-- <div class="user-panel">
            <div class="pull-left image">
              <img
                src="../assets/img/user2-160x160.jpg"
                class="img-circle"
                alt="User Image"
              />
            </div>
            <div class="pull-left info">
              <p>张猿猿</p>
              <a href="#"><i class="fa fa-circle text-success"></i> 在线</a>
            </div>
          </div> -->
          <!-- search form -->
          <!--<form action="#" method="get" class="sidebar-form">
            <div class="input-group">
                <input type="text" name="q" class="form-control" placeholder="搜索...">
                <span class="input-group-btn">
                <button type="submit" name="search" id="search-btn" class="btn btn-flat"><i class="fa fa-search"></i>
                </button>
              </span>
            </div>
        </form>-->
          <!-- /.search form -->

          <!-- sidebar menu: : style can be found in sidebar.less -->
          <ul class="sidebar-menu">
            <li class="header">菜单</li>

            <!-- <li id="admin-index">
              <a href="all-admin-index.html"
                ><i class="fa fa-dashboard"></i> <span>首页</span></a
              >
            </li> -->

            <!-- 菜单 -->

            <li class="treeview">
              <a href="#">
                <i class="fa fa-pie-chart"></i> <span>数据统计</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <li id="admin-login">
                  <router-link to="/datagrail">
                    <i class="fa fa-circle-o"></i> 数据大盘
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="treeview">
              <a href="#">
                <i class="fa fa-users"></i> <span>会员管理</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <li id="charts-chartjs">
                  <router-link to="/membermanagement">
                    <i class="fa fa-circle-o"></i> 所有会员
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="treeview">
              <a href="#">
                <i class="fa fa-edit"></i> <span>财务管理</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <!-- <li id="form-general">
                  <router-link to="/financialoverview">
                    <i class="fa fa-circle-o"></i>财务概况
                  </router-link>
                </li> -->
                <li id="form-general">
                  <router-link to="/advancerequest">
                    <i class="fa fa-circle-o"></i>提现申请
                  </router-link>
                </li>
                <li id="form-general">
                  <router-link to="/financialdetails">
                    <i class="fa fa-circle-o"></i>财务明细
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="treeview">
              <a href="#">
                <i class="fa fa-cube"></i> <span>订单管理</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <li id="order-manage">
                  <router-link to="/orderall">
                    <i class="fa fa-circle-o"></i> 所有订单
                  </router-link>
                </li>

                <!-- <li id="order-cancel">
                  <a href="all-order-cancel-list.html">
                    <i class="fa fa-circle-o"></i> 退款
                  </a>
                </li> -->
              </ul>
            </li>
            <li class="treeview">
              <a href="#">
                <i class="fa fa-user"></i> <span>个人中心</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <li id="admin-login">
                  <router-link to="/users?activeName=0">
                    <i class="fa fa-circle-o"></i> 基本设置
                  </router-link>
                </li>
                <li id="admin-login">
                  <router-link to="/users?activeName=1">
                    <i class="fa fa-circle-o"></i>分享海报
                  </router-link>
                </li>
                <li id="admin-login">
                  <router-link to="/users?activeName=2">
                    <i class="fa fa-circle-o"></i> 安全设置
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <!-- /.sidebar -->
      </aside>
      <!-- 导航侧栏 /-->

      <!-- 内容区域 -->
      <div class="content-wrapper">
        <!-- 内容头部 -->
        <router-view />
        <!-- 正文区域 /-->
      </div>
      <!-- 内容区域 /-->

      <!-- 底部导航 -->
      <!-- <footer class="main-footer">
        <div class="pull-right hidden-xs"><b>Version</b> 1.0.8</div>
        <strong
          >Copyright &copy; 2014-2017
          <a href="http://www.itcast.cn">研究院研发部</a>.</strong
        >
        All rights reserved.
      </footer> -->
      <!-- 底部导航 /-->
    </div>
    <!-- <div class="canvas-img"></div> -->
  </div>
</template>

<script>
export default {
  // name:"home"
  data() {
    return {
      userInfo: {},
    };
  },
  async created() {
    let data = await this.$http.get("/Auth/GetUserinfo");
    this.userInfo = data;
    sessionStorage.setItem("id", this.userInfo.InviteUserId);
  },
  mounted() {
    // let add = this.imgToCanvas(document.querySelector("#img-code"));
    // add.style.display="block";
    // console.log(add);
    // document.querySelector(".canvas-img").appendChild(add);
  },
  methods: {
    UserView() {
      this.$router.push("/users");
    },
    imgToCanvas(image) {
      console.log(image, image.width, image.height);
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      // canvas.getContext("2d").drawImage(image,0,0);
      image.onload = function () {
        canvas
          .getContext("2d")
          .drawImage(this, 0, 0, image.width, image.height);
      };
      return canvas;
    },
    RemoveUser() {
      localStorage.removeItem("userToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("tokenTime");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.hold-transition {
  height: 100%;
}
.navbar-static-top {
  padding: 0;
}
.canvas-img {
  position: absolute;
  bottom: 200px;
  right: 20px;
  z-index: 99;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
}
#img-code {
  border-radius: 0;
  width: 120px;
  height: 120px;
}
/* @import '../assets/attess/plugins/bootstrap/css/bootstrap.min.css' */
</style>