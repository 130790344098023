import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home_page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:'/loginregister',
  },
  {
    path: '/loginregister',
    name: 'loginregister',
    redirect:'/login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/LoginRegister.vue')
    },
    children:[  
      {
        path: '/login',
        name: 'LoginComponent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/Login.vue')
        }
      },
      {
        path: '/register',
        name: 'RegisterComponent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../components/Register.vue')
        }
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    redirect:'/datagrail',
    component: HomeView,
    children:[
      {
        path: '/users',
        name: 'users',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/UserView.vue')
        }
      },
      {
        path: '/datagrail',
        name: 'datagrail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/DataGrail.vue')
        }
      },
      {
        path: '/membermanagement',
        name: 'membermanagement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/MemberManagement.vue')
        }
      },{
        path: '/financialdetails',
        name: 'financialdetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/FinancialDetails.vue')
        }
      },{
        path: '/advancerequest',
        name: 'advancerequest',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/CashAdvanceRequest.vue')
        }
      },{
        path: '/financialoverview',
        name: 'financialoverview',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/FinancialOverview.vue')
        }
      },{
        path: '/orderall',
        name: 'ordersall',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/OrderAll.vue')
        }
      },{
        path: '/messageinform',
        name: 'messageinform',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/MessageInform.vue')
        }
      }
    ]
  },
  {
    path: '/succeed',
    name: 'succeed',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../components/RegisterSucceed.vue')
    }
  },
  {
    path: '/invitationcode',
    name: 'invitationcode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../components/InvitationCode.vue')
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
