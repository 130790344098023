import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import '@/assets/css/style.css'
// import axios from 'axios'
// Vue.prototype.$axios = axios;
import $http from '@/util/request'

Vue.prototype.$echarts = echarts;
Vue.prototype.$http = $http;
// Vue.prototype.$axios = axios;
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
