import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:{}
  },
  getters: {
    getUserInfo(state){
      return state.userInfo;
    }
  },
  mutations: {
    setUserInfo(state,payload){ 
      return state.userInfo = payload;
    },
  },
  actions: {
    userInfo(context,payload){
      context.commit("setUserInfo",payload);
    }
  },
  modules: {
  }
})
