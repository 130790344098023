import axios from "axios";
import Vue from "vue"
import NProgress from "nprogress";
import { showFullScreenLoading, hideFullScreenLoading } from "@/util/loading";

NProgress.configure({
    showSpinner: false
})
const $http = axios.create({
    // baseURL:"http://lqgapidemo.quechao.xyz",https://api.union.eztao.net/
    baseURL: "https://api.union.eztao.net/api",
    timeout: 5000,
});
$http.interceptors.request.use(
    config => {
        let white = ['/Auth/login', '/InviteCode', '/Auth/reg'];
        let arr = white.filter(ele => {
            return config.url.indexOf(ele) != -1;
        })
        if (arr.length <= 0) {
            let tokenTime = localStorage.getItem("tokenTime");
                if (tokenTime) {
                    if (tokenTime - parseInt(new Date().getTime() / 1000) <= 0) {
                        localStorage.removeItem("userToken");
                        localStorage.removeItem("tokenTime");
                        // this.$router.push("/login")
                    }
                }
            NProgress.start();
            let user = localStorage.getItem("userToken");
            if (!user) {
                window.location.hash = "/loginregister";
                // Vue.$router.push("/login");
            } else {
                config.headers.Authorization = user;
                
            }
        }
        return config;
    },
    error => {
        console.log("请求错误" + error);
        return Promise.reject(error);
    }
);
$http.interceptors.response.use(
    response => {
        // console.log(response);
        NProgress.done();
        hideFullScreenLoading();
        if (response.status != 200) {
            return false;
        }
        return response.data;
    }
);
export default $http